import React from 'react';
import styled from 'styled-components/macro';
import { MainButton } from '../assets/GlobalStyles';

const DocumentsWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 35px 30px 50px 30px;
  padding-top: ${(props) => props.paddingTop};

  @media (max-width: 768px) {
    padding-top: ${(props) => props.paddingTopMobile};
  }
`;

const NewsText = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 50px 0;

  h2 {
    margin-bottom: 20px;
  }

  p {
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 5px;
  }

  @media (max-width: 960px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Document = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid hsl(255 0% 85%);
  padding: 0;

  @media (max-width: 585px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .document-text {
    @media (max-width: 768px) {
      width: 60%;
      padding-right: 15px;
    }

    @media (max-width: 585px) {
      width: 100%;
      padding-right: 0;
    }
  }
`;

const Documents = ({ documents, paddingTop, paddingTopMobile }) => {
  // console.log(documents);
  return (
    <DocumentsWrapper
      paddingTop={paddingTop}
      paddingTopMobile={paddingTopMobile}
    >
      <NewsText>
        <h2>Dokument</h2>
        {documents &&
          documents.map((doc, index) => {
            return (
              <Document key={doc._id}>
                <div className="document-text">
                  <h3>{doc.title}</h3>
                  <p>
                    uppladdad {doc.uploadDate}{' '}
                    {doc.author ? `av ${doc.author}` : ''}
                  </p>
                </div>
                <MainButton
                  href={doc.documentURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={doc.documentURL}
                >
                  Ladda ner
                </MainButton>
              </Document>
            );
          })}
      </NewsText>
    </DocumentsWrapper>
  );
};

export default Documents;
