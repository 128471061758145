import React from "react";
import styled from 'styled-components/macro';

const FooterWrapper = styled.section`
        width: 100%;
        margin: auto;
        height: 20vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            margin-top: 20px;
            font-size: 12px;
        }
        
        .cookieworks {
            color: hsl(255 0% 60%);
            font-size: 10px;
            
            a {
                color: hsl(255 0% 60%);
                text-decoration: none;
            }
        }
    `;

const Footer = () => {
    return (
        <FooterWrapper>
            <img src="./images/samfalligheten-seatons-strand-logo-footer.svg" alt="Footer logo" />
            <p>Copyright © 2022     |    Seatons Strand</p>
            <p className="cookieworks">Webpage by <a href="https://cookieworks.se" target="_blank" rel="noopener noreferrer">Cookie Dev & Design</a></p>
        </FooterWrapper>
            )
        };

export default Footer;
