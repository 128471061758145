import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import sanityClient from '../client.js';

// COMPONENTS
import Documents from '../components/Documents';
import Footer from '../components/Footer';

const MainOuterWrapper = styled.main`
  width: 100%;
  max-width: 100vw;
  position: relative;
`;

const InputBox = styled.div`
  position: absolute;
  width: 80%;
  z-index: 100000;
  padding: 150px;
  left: calc(50%);
  top: calc(70%);
  background: #fff;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.1);
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  p {
    margin-bottom: 20px;
  }

  input {
    padding: 6px 8px;
    line-height: 1.6;
  }

  button {
    padding: 6px 20px;
    margin-left: 20px;
    line-height: 1.6;
  }
`;

const DocumentsPage = () => {
  const [documentData, setDocumentData] = useState();
  const [inputVisible, setInputVisible] = useState(true);
  const [message, setMessage] = useState('');
  const [errorShowing, setErrorShowing] = useState(false);

  const handleMessageChange = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const okClick = (e) => {
    e.preventDefault();
    setMessage('');

    sanityClient
      .fetch(
        `*[_type == 'word']{
                        _id,
                        word,
                    }`
      )
      .then((wordData) => {
        if (message === wordData[1].word) {
          sessionStorage.setItem('valid', true);
          setInputVisible(false);

          // Documents
          sanityClient
            .fetch(
              `*[_type == 'documents']| order(_createdAt asc){
                        _id,
                        title,
                        document,
                        "documentURL": document.asset->url,
                        uploadDate,
                        author
                    }`
            )
            .then((data) => setDocumentData(data))
            .catch(console.error);
        } else {
          setErrorShowing(true);
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (sessionStorage.getItem('valid')) {
      setInputVisible(false);

      sanityClient
        .fetch(
          `*[_type == 'documents']| order(_createdAt asc) {
                        _id,
                        title,
                        document,
                        "documentURL": document.asset->url,
                        uploadDate,
                        author
                    }`
        )
        .then((data) => setDocumentData(data))
        .catch(console.error);
    }
  }, []);

  return (
    <MainOuterWrapper>
      {inputVisible && (
        <InputBox>
          <p>Du behöver skriva in ett lösenord för att ta del av dokumenten.</p>
          <form>
            <input
              id="message"
              value={message}
              name="message"
              type="password"
              autoComplete="current-password"
              onChange={handleMessageChange}
              placeholder="Lösenord..."
              required
            ></input>
            <button
              type="submit"
              className="input-button"
              onClick={(event) => okClick(event)}
            >
              OK
            </button>
            {errorShowing && (
              <p
                style={{
                  color: 'red',
                  position: 'absolute',
                  top: '70%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  maxWidth: '400px',
                }}
              >
                Fel lösenord, prova igen eller kontakta styrelsen för att ta del
                av senaste lösenordet.
              </p>
            )}
          </form>
        </InputBox>
      )}
      {/* <button
        style={{ position: 'relative', zIndex: '500000000' }}
        onClick={() => setInputVisible(true)}
      >
        show box
      </button> */}
      <Documents
        documents={documentData}
        paddingTop="200px"
        paddingTopMobile="80px"
      />
      <Footer />
    </MainOuterWrapper>
  );
};

export default DocumentsPage;
