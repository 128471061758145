import React from 'react';
import styled from 'styled-components/macro';
import { MainButton } from '../assets/GlobalStyles';

const ContactWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 35px 30px 50px 30px;
  padding-top: ${(props) => props.paddingTop};

  @media (max-width: 768px) {
    padding-top: ${(props) => props.paddingTopMobile};
  }
`;

const ContactInnerWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 50px 0;

  h2 {
    margin-bottom: 20px;
  }

  .title {
    text-transform: uppercase;
    font-size: 12px;
    margin: 5px 0;
  }

  @media (max-width: 960px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  .contact-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }

  .people {
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .form-wrapper {
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }

    > p {
      margin-bottom: 20px;
    }
  }

  .form {
    background: #d8dbc7;
    padding: 40px 50px;

    form {
      display: flex;
      flex-direction: column;

      input,
      textarea {
        background: none;
        border: 1px solid #000;
        margin-bottom: 10px;
        font-size: 16px;
        padding: 10px;
      }

      input:active,
      textarea:active {
        border: 1px solid #fff;
      }

      textarea {
        min-height: 200px;
      }

      label {
        text-transform: uppercase;
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 2px;
      }

      .submit-btn {
        width: fit-content;
        padding: 10px 40px;
        background: #000;
        color: #fff;
        text-decoration: none;
        border: none;
        display: inline;
        margin: 20px 10px 0 0;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
          background: hsl(255 0% 25%);
        }

        &:active {
          background: #d8dbc7;
        }
      }
    }
  }
`;

const Person = styled.div`
  width: 50%;
  display: inline-flex;
  flex-direction: column;
  margin: 0 0 40px 0;

  @media (max-width: 350px) {
    width: 100%;
  }
`;

const Contact = ({ data, paddingTop, paddingTopMobile }) => {
  return (
    <ContactWrapper paddingTop={paddingTop} paddingTopMobile={paddingTopMobile}>
      <ContactInnerWrapper>
        <h2>Kontakt</h2>

        <div className="contact-content">
          <div className="people">
            {data &&
              data[0].contacts.map((person, index) => {
                return (
                  <Person key={index}>
                    <p className="title">{person.title}</p>
                    <p className="name">{person.name}</p>
                    <p className="number">{person.phone}</p>
                  </Person>
                );
              })}
          </div>
          <div className="form-wrapper">
            <p>
              Kontakta gärna styrelsen genom formuläret nedan, eller skicka ett
              mejl till styrelsen@seatonsstrand.se
            </p>
            <div className="form">
              <form
                form-name="contact"
                name="contact"
                method="post"
                action="/"
                data-netlify="true"
              >
                <input type="hidden" name="form-name" value="contact" />

                <label htmlFor="name">Namn</label>
                <input name="name" id="name" type="text" required />

                <label htmlFor="email">E-post</label>
                <input name="email" id="email" type="email" required />

                <label htmlFor="phone">Telefonnummer</label>
                <input name="phone" id="phone" type="text" />

                <label htmlFor="message">Meddelande</label>
                <textarea name="message" id="message" required></textarea>

                <button type="submit" className="submit-btn">
                  Skicka
                </button>
              </form>
            </div>
          </div>
        </div>
      </ContactInnerWrapper>
    </ContactWrapper>
  );
};

export default Contact;
