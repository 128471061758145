import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import sanityClient from '../client.js';

// COMPONENTS
import Calendar from '../components/Calendar';
import Footer from '../components/Footer';

const MainOuterWrapper = styled.main`
  width: 100%;
  max-width: 100vw;
  position: relative;
`;

const CalendarPage = () => {
  const [calendarData, setCalendarData] = useState();

  useEffect(() => {
    // Events
    sanityClient
      .fetch(
        `*[_type == 'events']{
                            _id,
                            title,
                            intro,
                            date,
                            "mainImage":mainImage.asset->{url, tags, title},
                            body,
                        }`
      )
      .then((data) => setCalendarData(data))
      .catch(console.error);
  }, []);
  return (
    <MainOuterWrapper>
      <Calendar
        data={calendarData}
        paddingTop="200px"
        paddingTopMobile="80px"
      />
      <Footer />
    </MainOuterWrapper>
  );
};

export default CalendarPage;
