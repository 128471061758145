import React, { useState, useEffect } from "react";
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const NavWrapper = styled.section`
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 35px 30px 50px 30px;
        position: absolute;
        z-index: 5;
        background: ${(props) => !props.menuClosed ? 'none' : '#fff'};
        transform: ${(props) => !props.menuClosed ? 'translateY(-50%)' : 'translateY(0%)'};
        opacity: ${(props) => !props.menuClosed ? '0' : '1'};
        transition: all .5s ease-in-out;

        a.logo-nav {
            display: none;
            width: 200px;
            margin: 0 auto;
        }
        `;

const NavBar = styled.nav`
    display: none;
    text-transform: uppercase;
    position: relative;
    padding-top: 100px;

    a {
        font-weight: 400;
        letter-spacing: 0.5px;
        color: #3F3F3F;
        text-decoration: none;
        border-bottom: 1px solid transparent;
    }
    
    a:hover {
        border-bottom: 1px solid #3F3F3F;
    }

    @media (max-width:768px) {
        display: block;
        width: 100%;
        flex-direction: column;
        
        a {
            border-bottom: 1px solid #000;
            display: block;
            width: 100%;
            text-align: center;
            padding: 10px 0;
        }

        a:first-child {
            border-top: 1px solid #000;
        }
    }
`; 

const Hamburger = styled.div`

@media (min-width: 768px) {
    display: none;
}

  display: flex;
  flex-direction: column;
  position: absolute;
  right: 30px;
  top: 35px;
  z-index: 20;
  cursor: pointer;

  div {
      width: 40px;
      background: #000;
      height: 3px;
      margin: 4px 0;
  }
`;

const MobileNav = () => {
    const [menuClosed, setMenuClosed] = useState(false);

    const hamburgerHandler = () => {
        setMenuClosed(!menuClosed)
    }

    return (
        <>
            <Hamburger onClick={hamburgerHandler}>
                <div></div>
                <div></div>
                <div></div>
            </Hamburger>   
            
            <NavWrapper menuClosed={menuClosed}>
                <Link className="logo-nav" to="/"><img alt="Logo" src="./images/samfalligheten-seatons-strand-logo-black.svg" /></Link>

                <NavBar>
                    <Link to="/nyheter" onClick={() => setMenuClosed(!menuClosed)}>Nyheter</Link>
                    <Link to="/kalender" onClick={() => setMenuClosed(!menuClosed)}>Kalender</Link>
                    <Link to="/dokument" onClick={() => setMenuClosed(!menuClosed)}>Dokument</Link>
                    <Link to="/kontakt" onClick={() => setMenuClosed(!menuClosed)}>Kontakt</Link>
                </NavBar>
            </NavWrapper>
        </>
            )
        };

export default MobileNav;
