import React from 'react';
import styled from 'styled-components/macro';
import { PortableText } from '@portabletext/react';
import { useLocation } from 'react-router-dom';
import { MainHashLink } from '../assets/GlobalStyles';

const NewsWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 35px 30px 50px 30px;
  padding-top: ${(props) => props.paddingTop};

  @media (max-width: 768px) {
    padding-top: ${(props) => props.paddingTopMobile};
    padding-bottom: 0;
  }
`;

const NewsText = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 50px 0;

  @media (max-width: 960px) {
    width: 90%;
    padding: 50px 0 0;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const NewsItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: ${(props) => (props.location === '/' ? 'center' : 'flex-start')};
  margin: 30px 0;

  img {
    width: 400px;
  }

  h3 {
    font-size: 26px;
    padding-bottom: 5px;
  }

  .text-wrapper {
    padding: 0 40px;
  }

  .posted-at {
    letter-spacing: 10%;
    font-size: 12px;
    text-transform: uppercase;
  }

  .body-text {
    margin: 20px 0;
    max-width: 600px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    img {
      width: 100%;
    }

    .text-wrapper {
      width: 100%;
      padding: 30px 0 10px;
    }
  }
`;

const News = ({ newsData, paddingTop, paddingTopMobile }) => {
  const location = useLocation();

  return (
    <NewsWrapper paddingTop={paddingTop} paddingTopMobile={paddingTopMobile}>
      <NewsText>
        <h2>Senaste nytt</h2>

        {newsData &&
          newsData.reverse().map((item) => {
            return (
              <NewsItemWrapper
                key={item._id}
                location={location.pathname}
                id={item._id}
              >
                <img
                  className="news-image"
                  src={
                    item.mainImage
                      ? item.mainImage.url
                      : './images/seatons-strand-placeholder.jpg'
                  }
                  alt={item.title}
                />
                <div className="text-wrapper">
                  <h3>{item.title}</h3>
                  <p className="posted-at">Postat {item.publishedAt}</p>
                  <p style={{ marginTop: '20px' }}>{item.intro}</p>
                  {location.pathname === '/' && (
                    <MainHashLink to={`/nyheter#${item._id}`}>
                      Läs mer
                    </MainHashLink>
                  )}
                  {location.pathname !== '/' && (
                    <div className="body-text">
                      <PortableText value={item.body} />
                    </div>
                  )}
                </div>
              </NewsItemWrapper>
            );
          })}
      </NewsText>
    </NewsWrapper>
  );
};

export default News;
