import React from "react";
import styled from 'styled-components/macro';
import { MainLink } from '../assets/GlobalStyles'

const MoreButtonWrapper = styled.div`
    width: 100%;
    transition: all .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 30px 0;
    `;

const MoreButton = ({ buttonText, to }) => {
    return (
        <MoreButtonWrapper>
            <MainLink to={to}>{buttonText}</MainLink>
        </MoreButtonWrapper>
            )
        };

export default MoreButton;
