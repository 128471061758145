import React from 'react';
import styled from 'styled-components/macro';
import { MainHashLink } from '../assets/GlobalStyles';
import { PortableText } from '@portabletext/react';
import { useLocation } from 'react-router-dom';

const CalendarWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 35px 30px 50px 30px;
  position: relative;
  padding-top: ${(props) => props.paddingTop};

  @media (max-width: 768px) {
    padding-top: ${(props) => props.paddingTopMobile};
  }
`;

const CalendarText = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 50px 0;

  @media (max-width: 960px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CalendarItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin: 30px 0;

  img {
    width: 300px;
  }

  h3 {
    font-size: 26px;
    margin: 0 0 0px 0;
  }

  .text-wrapper {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .date {
    letter-spacing: 10%;
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
    /* left: 20px;

        &:before {
            position: absolute;
            content: '➜';
            left: -20px;
        } */
  }

  .body-text {
    p {
      margin: 5px 0 15px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    img {
      width: 100%;
    }

    .text-wrapper {
      width: 100%;
      padding: 30px 0;
    }
  }
`;

const CalendarPopup = styled.div`
  display: none;
  position: absolute;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: #d8dbc7;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:after {
    position: absolute;
    content: '⤫';
    color: #fff;
    z-index: 20;
    font-size: 40px;
    top: 10px;
    right: 25px;
  }

  .big-image {
    width: 100%;
    position: relative;
  }

  .text-wrapper {
    padding: 20px 50px;
  }
`;

const Calendar = ({ data, paddingTop, paddingTopMobile }) => {
  const location = useLocation();

  return (
    <CalendarWrapper
      paddingTop={paddingTop}
      paddingTopMobile={paddingTopMobile}
    >
      <CalendarText>
        <h2>Kalender</h2>
        {data && data.length === 0 && (
          <p style={{ margin: '10px 0' }}>
            Inga händelser inlagda i kalendern.
          </p>
        )}
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return (
              <CalendarItemWrapper key={item._id} id={item._id}>
                <img
                  className="news-image"
                  src={
                    item.mainImage
                      ? item.mainImage.url
                      : './images/seatons-strand-placeholder.jpg'
                  }
                  alt={item.title}
                />
                <div className="text-wrapper">
                  <p className="date">{item.date}</p>
                  <h3>{item.title}</h3>
                  <div className="body-text">
                    <p>{item.intro}</p>
                    {location.pathname === '/' && (
                      <MainHashLink to={`/kalender#${item._id}`}>
                        Läs mer
                      </MainHashLink>
                    )}
                    {/* <MainButton>Lägg till i kalender</MainButton> */}
                    {location.pathname !== '/' && (
                      <PortableText value={item.body} />
                    )}
                  </div>
                </div>
              </CalendarItemWrapper>
            );
          })}
      </CalendarText>
    </CalendarWrapper>
  );
};

export default Calendar;
