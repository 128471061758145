import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const MainButton = styled.a`
  padding: 10px 40px;
  background: #000;
  color: #fff;
  text-decoration: none;
  border: none;
  display: inline-block;
  margin: 20px 10px 20px 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: hsl(255 0% 25%);
  }

  &:active {
    background: #d8dbc7;
  }
`;

export const MainLink = styled(Link)`
  padding: 10px 40px;
  background: #000;
  color: #fff;
  text-decoration: none;
  border: none;
  display: inline-block;
  margin: 20px 10px 20px 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: hsl(255 0% 25%);
  }

  &:active {
    background: #d8dbc7;
  }
`;

export const MainHashLink = styled(HashLink)`
  padding: 10px 40px;
  background: #000;
  color: #fff;
  text-decoration: none;
  border: none;
  display: inline-block;
  margin: 20px 10px 20px 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: hsl(255 0% 25%);
  }

  &:active {
    background: #d8dbc7;
  }
`;
