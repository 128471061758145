import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const HeroWrapper = styled.section`
  width: 100%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 35px 30px 50px 30px;
  position: relative;
  background-image: url(${(props) => props.data.hero_image.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center -20px;

  img {
    width: 200px;
    margin: 0 auto;
  }
`;

const HeroText = styled.div`
  color: #fff;
  width: 80%;
  margin: 0 auto;
  padding: 50px 0 170px;

  @media (max-height: 760px) {
    padding: 50px 0 120px;
  }

  @media (max-height: 600px) {
    padding: 50px 0 60px;
  }

  h1 {
    font-size: 42px;
    width: 40%;
    line-height: 1.1;
    padding-bottom: 20px;
  }

  p {
    width: 40%;
    font-size: 1.1rem;
    line-height: 1.6;
  }

  @media (max-width: 960px) {
    width: 100%;

    h1 {
      width: 100%;
    }
    p {
      width: 80%;
    }
  }

  @media (max-width: 680px) {
    h1 {
      font-size: 36px;
    }
    p {
      width: 90%;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 36px;
    }
    p {
      margin-top: 20px;
      width: 100%;
    }
  }
`;

const MobileNav = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  width: 100%;
  display: flex;
  flex-direction: column;

  a {
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid #fff;
    display: block;
    width: 100%;
    padding: 10px 0;
  }
`;

const Hero = ({ data }) => {
  return (
    data && (
      <HeroWrapper data={data}>
        <HeroText>
          <h1>{data.hero_title}</h1>
          <p>{data.hero_intro}</p>
        </HeroText>

        <MobileNav>
          <Link to="/nyheter">Nyheter</Link>
          <Link to="/kalender">Kalender</Link>
          <Link to="/dokument">Dokument</Link>
          <Link to="/kontakt">Kontakt</Link>
        </MobileNav>
      </HeroWrapper>
    )
  );
};

export default Hero;
