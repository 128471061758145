import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import sanityClient from '../client.js';

// COMPONENTS
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const MainOuterWrapper = styled.main`
  width: 100%;
  max-width: 100vw;
  position: relative;
`;

const ContactPage = () => {
  const [homeData, setHomeData] = useState();

  useEffect(() => {
    // Home page
    sanityClient
      .fetch(
        `*[_type == 'home']{
                    _id,
                    news,
                    documents,
                    calendar,
                    contacts[]->{ _id, name, title, phone, email },
                    calendar[]->{ _id, title, intro, date, mainImage, body },
                    }`
      )
      .then((data) => setHomeData(data))
      .catch(console.error);
  }, []);

  return (
    <MainOuterWrapper>
      <Contact data={homeData} paddingTop="200px" paddingTopMobile="80px" />
      <Footer />
    </MainOuterWrapper>
  );
};

export default ContactPage;
