import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import sanityClient from '../client.js';

// COMPONENTS
import Hero from '../components/Hero';
import FullWidthImage from '../components/FullWidthImage';
import News from '../components/News';
import Calendar from '../components/Calendar';
import MoreButton from '../components/MoreButton';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const MainOuterWrapper = styled.main`
  width: 100%;
  max-width: 100vw;
  position: relative;
`;

const Main = () => {
  const [homeData, setHomeData] = useState();

  useEffect(() => {
    // Home page
    sanityClient
      .fetch(
        `*[_type == 'home']{
                    _id,
                    hero_title,
                    hero_intro,
                    "hero_image":hero_image.asset->{url, tags, title},
                    news[]->{
                    _id,
                    title,
                    intro,
                    publishedAt,
                    "mainImage":mainImage.asset->{url, tags, title},
                    slug,
                    body,
                },
                    documents[]->{_id, title, document, "documentURL": document.asset->url, uploadDate, author},
                    calendar,
                    contacts[]->{ _id, name, title, phone, email },
                    calendar[]->{ _id, title, intro, date, "mainImage":mainImage.asset->{url, tags, title}, body },
                    }`
      )
      .then((data) => setHomeData(data))
      .catch(console.error);
  }, []);

  return (
    homeData && (
      <MainOuterWrapper>
        <Hero data={homeData[0]} />
        <News newsData={homeData[0].news.reverse()} paddingTop="35px" />
        <MoreButton buttonText="Till alla nyheter" to="/nyheter" />
        {homeData[0].calendar.length > 0 && (
          <Calendar data={homeData[0].calendar} />
        )}
        <FullWidthImage />
        <Contact data={homeData} />
        <Footer />
      </MainOuterWrapper>
    )
  );
};

export default Main;
