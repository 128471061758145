import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const NavWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 35px 30px 50px 30px;
  position: absolute;
  z-index: 10;

  a.logo-nav {
    width: 200px;
    margin: 0 auto;

    @media (max-width: 768px) {
      margin: 0;
    }
  }
`;

const NavBar = styled.nav`
  width: 60%;
  margin: 40px auto auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;

  a {
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #3f3f3f;
    text-decoration: none;
    border-bottom: 1px solid transparent;
  }

  a:hover {
    border-bottom: 1px solid #3f3f3f;
  }

  @media (max-width: 768px) {
    display: none;
    width: 100%;
    flex-direction: column;

    a {
      border-bottom: 1px solid #000;
      display: block;
      width: 100%;
      text-align: center;
      padding: 10px 0;
    }

    a:first-child {
      border-top: 1px solid #000;
    }
  }
`;

const Nav = () => {
  return (
    <NavWrapper>
      <Link className="logo-nav" to="/">
        <img
          alt="Logo"
          src="./images/samfalligheten-seatons-strand-logo-black.svg"
        />
      </Link>

      <NavBar>
        <Link to="/nyheter">Nyheter</Link>
        <Link to="/kalender">Kalender</Link>
        <Link to="/dokument">Dokument</Link>
        {/* <a
          className="dok-link"
          href="/"
          onClick={(event) => clickHandlerDok(event)}
        >
          Dokument
        </a> */}
        <Link to="/kontakt">Kontakt</Link>
      </NavBar>
    </NavWrapper>
  );
};

export default Nav;
