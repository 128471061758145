import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import sanityClient from '../client.js';

// COMPONENTS
import News from '../components/News';
import OldNews from '../components/OldNews';
import Footer from '../components/Footer';

const MainOuterWrapper = styled.main`
  width: 100%;
  max-width: 100vw;
  position: relative;
`;

const NewsPage = () => {
  const [newsData, setNewsData] = useState();

  useEffect(() => {
    // News
    sanityClient
      .fetch(
        `*[_type == 'news']{
                    _id,
                    title,
                    intro,
                    publishedAt,
                    "mainImage":mainImage.asset->{url, tags, title},
                    slug,
                    body,
                }`
      )
      .then((data) => setNewsData(data))
      .catch(console.error);
  }, []);

  return (
    <MainOuterWrapper>
      <News newsData={newsData} paddingTop="200px" paddingTopMobile="80px" />
      <OldNews newsData={newsData} />
      <Footer />
    </MainOuterWrapper>
  );
};

export default NewsPage;
