import React from "react";
import styled from 'styled-components/macro';

const ImageWrapper = styled.section`
        width: 100%;
        height: 50vh;
        background-image: url("./images/timothy-meinberg-phx4UXMQRTg-unsplash.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    `;

const FullWidthImage = () => {
    return (
        <ImageWrapper>
            
        </ImageWrapper>
            )
        };

export default FullWidthImage;
