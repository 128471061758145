import React from 'react';
import styled from 'styled-components/macro';
import { PortableText } from '@portabletext/react';
import { toHTML } from '@portabletext/to-html';

const NewsWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 30px 50px 30px;

  h3 {
    font-size: 24px;
  }
`;

const AllItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const NewsText = styled.div`
  width: 80%;
  margin: 0 auto;

  @media (max-width: 960px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const NewsItemWrapper = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    margin: 20px 0;

    img {
            width: 40%;
            margin-right: 30px;
    }

    h4 {
        font-size: 18px;
        padding-bottom: 5px;
    }

    .text-wrapper {
        /* padding: 0 40px; */
        width: 40%;

    }

    .posted-at {
        letter-spacing: 10%;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .body-text {
        margin: 20px 0 0
        max-width: 600px;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 440px) {
        width: 100%;
        flex-direction: column;

        img {
            width: 100%;
            margin-right: 0;
        }

        .text-wrapper {
            width: 100%;
            padding: 20px 0;
        }
    }
`;

const OldNews = ({ newsData, paddingTop }) => {
  return (
    <NewsWrapper paddingTop={paddingTop}>
      <NewsText>
        <h3>Äldre nyheter</h3>
        <AllItemsWrapper>
          {newsData && newsData.length < 4 && (
            <div style={{ marginTop: '20px' }}>
              Inga äldre nyheter att visa.
            </div>
          )}
          {newsData &&
            newsData.map((item, index) => {
              if (index > 2) {
                return (
                  <NewsItemWrapper key={item._id}>
                    <img
                      className="news-image"
                      src={
                        item.mainImage
                          ? item.mainImage.url
                          : './images/seatons-strand-placeholder.jpg'
                      }
                      alt={item.title}
                    />
                    <div className="text-wrapper">
                      <h4>{item.title}</h4>
                      <p className="posted-at">Postat {item.publishedAt}</p>
                      <div className="body-text">
                        <PortableText value={item.body} />
                      </div>
                    </div>
                  </NewsItemWrapper>
                );
              }
            })}
        </AllItemsWrapper>
      </NewsText>
    </NewsWrapper>
  );
};

export default OldNews;
